import { SystemPageKey } from '@internal/page/SystemPageKey'
import { getGeoLocation } from '@internal/utils/geoLocation/getGeoLocation'
import { TMachineDetailsRouteParams } from '@internal/utils/routing/routingTable'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import Cookies from 'cookies'
import type { GetServerSideProps, NextPage } from 'next'
import { BasePage } from 'page/BasePage'
import { TPageProps } from 'page/TPageProps'
import { ParsedUrlQuery } from 'querystring'
import React from 'react'
import { RequestCombiner } from 'services/RequestCombiner'
import { getRedirect } from 'services/cmsClient'
import { defaultLocale, undefinedLocale } from 'utils/constants'
import { initFeatureFlags } from 'utils/featureFlags/initFeatureFlags'
import { getMachineTypeForMachineTypeSlug } from 'utils/machine/getMachineTypeForMachineTypeSlug'
import { PageTypeKey } from 'utils/page/PageTypeKey'
import { getPageProps } from 'utils/page/getPageProps'
import { getRequestIp } from 'utils/request/getRequestIp'
import * as router from 'utils/routing/router'
import { urlFor } from 'utils/routing/urls/urlFor'

export const getServerSideProps: GetServerSideProps<TPageProps> = async (
  context
) => {
  const requestCombiner = new RequestCombiner()
  const url = context.resolvedUrl.split('?')[0]
  const locale =
    context.locale === undefinedLocale
      ? (defaultLocale as TLocale)
      : (context.locale as TLocale)
  const { slugs, ff, ...restQueryParams } = context.query
  const queryParams = restQueryParams as ParsedUrlQuery
  const slug = Array.from(slugs ?? []).join('/')

  const host = context.req.headers.host ?? null
  // allow override of ip via query param for testing
  const ip =
    (queryParams.ip ? `${queryParams.ip}` : null) || getRequestIp(context.req)

  const location = await getGeoLocation(locale)

  const featureFlags = initFeatureFlags(
    new Cookies(context.req, context.res),
    Array.isArray(ff) ? '' : ff
  )

  // 1 SYSTEM PAGE ____________________________________________________________
  const systemPageRoutingResult = await router.matchSystemPage(
    urlFor(locale, url),
    queryParams
  )

  if (systemPageRoutingResult) {
    let machineType: MachineType | null = null
    if (systemPageRoutingResult.systemPageKey === SystemPageKey.PDP) {
      machineType = await getMachineTypeForMachineTypeSlug(
        locale,
        (
          systemPageRoutingResult.pathParams as TMachineDetailsRouteParams['pathParams']
        ).machineType
      )
    }

    return getPageProps({
      type: PageTypeKey.SystemPage,
      key: systemPageRoutingResult.systemPageKey,
      context,
      queryParams: context.query,
      pathParams: systemPageRoutingResult.pathParams,
      ip,
      host,
      requestCombiner,
      featureFlags,
      location,
      machineType,
    })
  }

  // 2 MACHINE CATEGORY PAGE __________________________________________________
  const machineCategoryPageRoutingResult =
    await router.matchMachineCategoryPage(
      urlFor(locale, url),
      locale,
      queryParams
    )

  if (machineCategoryPageRoutingResult) {
    const machineType = await getMachineTypeForMachineTypeSlug(
      locale,
      machineCategoryPageRoutingResult.params.pathParams.machineTypeSlug
    )

    if (machineType) {
      const pageProps = await getPageProps({
        type: PageTypeKey.MachineCategoryPage,
        context,
        machineType,
        queryParams: context.query,
        pathParams: machineCategoryPageRoutingResult.params.pathParams,
        ip,
        host,
        requestCombiner,
        featureFlags,
        location,
      })

      if (!(pageProps as any).notFound) {
        return pageProps
      }
    }
  }

  // 3 CMS PAGE _______________________________________________________________
  const pageProps = await getPageProps({
    type: PageTypeKey.Page,
    slug: encodeURIComponent(slug),
    context,
    queryParams: context.query,
    pathParams: {},
    ip,
    host,
    requestCombiner,
    featureFlags,
    location,
  })

  if (!(pageProps as any).notFound) {
    return pageProps
  }

  // 4 INTERNAL REDIRECTS _____________________________________________________
  const redirectRoutingResult = await router.matchRedirect({
    url: urlFor(locale, url),
    queryParams,
    locale,
    ip,
  })

  if (redirectRoutingResult) {
    return {
      redirect: {
        statusCode: 301,
        destination: redirectRoutingResult.target,
      },
    }
  }

  // 5 CMS REDIRECTS ________________________________________________________
  const redirect = await getRedirect(encodeURIComponent(`/${locale}/${slug}/`))
  if (redirect) {
    return {
      redirect: {
        statusCode: 301,
        destination: redirect,
      },
    }
  }

  // 6 NOT FOUND ______________________________________________________________
  return {
    notFound: true,
  }
}

const Page: NextPage<TPageProps> = (props) => <BasePage {...props} />

export default React.memo(Page)
